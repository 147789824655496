<template>
  <v-app dark>
    <router-view />
  </v-app>
</template>

<script>
export default {
  created() {
    let vh = window.innerHeight * 0.01;
    // Then we set the value in the --vh custom property to the root of the document
    document.documentElement.style.setProperty('--vh', `${vh}px`);
  },
};
</script>

<style>
body,
html {
  height: 100%;
  color: white;
  font-family: "Source Sans Pro";
}
.main {
  height: 100%;
  min-width: 375px;
  background-image: url("/img/background.svg");
  width: 100%;
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  background-color: white;
}
.container {
  padding-bottom: 0px !important;
  display: flex;
  min-height: calc(var(--vh, 1vh) * 100 - 112px);
}
.w-full{
  width: 100%;
}
.m-0{
  margin: 0px;
}
.mx-0{
  margin-right: 0px;
  margin-left: 0px;
}
@media only screen and (max-width: 960px) {
  body,
  html {
    overflow: auto !important;
  }
}
@media (min-width: 1264px) {
  .container {
      max-width: 1280px !important;
  }
}
</style>
