<template>
  <div class="topbar">
    <div class="nav">
      <a @click.stop="contact = true" class="header-link contact">
        Contact
      </a>
      <router-link to="/terms" class="header-link terms"><span>Terms</span></router-link>
      <router-link to="/privacy" class="header-link privacy"><span>Privacy Policy</span></router-link>
    </div>
    <div class="mobile-menu">
      <v-btn text dark icon @click.stop="menu = true">
        <v-icon>mdi-menu</v-icon>
      </v-btn>
    </div>
    <router-link class="logo header-link" to="/">
      <img src="/img/logo.png" />
      <span>FITSHARE</span>
    </router-link>
    <v-navigation-drawer v-model="menu" absolute temporary width="400px" light>
      <v-btn text icon @click.stop="menu = false">
        <v-icon size="30">mdi-close</v-icon>
      </v-btn>
      <div class="contact-content menu">
        <router-link class="logo header-link" to="/">
          <img src="/img/logo.png" />
          <span>FITSHARE</span>
        </router-link>
        <div class="menu-list">
          <a
            text
            dark
            icon
            @click.stop="
              menu = false;
              contact = true;
            "
            class="header-link"
          >
            <span>
              Contact
            </span>
          </a>
          <router-link to="/terms" class="header-link"><span>Terms</span></router-link>
          <router-link to="/privacy" class="header-link"><span>Privacy Policy</span></router-link>
        </div>
      </div>
    </v-navigation-drawer>

    <v-navigation-drawer v-model="contact" absolute temporary width="400px" light>
      <v-btn text icon @click.stop="contact = false">
        <v-icon size="30">mdi-close</v-icon>
      </v-btn>
      <div class="contact-content">
        <h1>Contact us</h1>
        <v-text-field background-color="#b3b0b0" dark solo style="border-radius:10px;margin-bottom:10px" height="45" placeholder="Name" v-model="name"></v-text-field>
        <v-text-field background-color="#b3b0b0" dark solo style="border-radius:10px;margin-bottom:10px" height="45" placeholder="Email" :rules="rules" v-model="email"></v-text-field>
        <v-textarea solo label="Message" auto-grow background-color="#b3b0b0" dark rows="5" v-model="message" style="border-radius:10px;margin-bottom:10px"></v-textarea>
        <vue-recaptcha sitekey="6LdLhKsZAAAAAMSDmlsc7JE6SlJiEaGCX6CFqdIt" ref="recaptcha" :loadRecaptchaScript="true" @verify="onVerify"></vue-recaptcha>
        <v-btn color="#630d0d" dark height="45" width="120" style="border-radius:10px;font-size:15pt;margin-top:20px;" @click.stop="sendMessage">Send it</v-btn>
      </div>
    </v-navigation-drawer>
  </div>
</template>

<script>
import axios from "axios";
import VueRecaptcha from "vue-recaptcha";
export default {
  components: { VueRecaptcha },
  data() {
    return {
      contact: null,
      menu: null,
      name: null,
      message: null,
      email: null,
      verified: false,
      rules: [
        (value) => (value || "").length <= 30 || "Max 30 characters",
        (value) => {
          const pattern = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
          return pattern.test(value) || "Invalid e-mail.";
        },
      ],
    };
  },
  methods: {
    async sendMessage() {
      if (this.name === "" || this.message === "" || this.email === "" || !this.verified) return;
      const pattern = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
      if (!pattern.test(this.email)) return;
      axios.post(
        "https://us-central1-fitshare-6eb2a.cloudfunctions.net/sendMail",
        {
          email: this.email,
          name: this.name,
          content: this.message,
        },
        {
          headers: {
            "content-type": "application/json;charset=UTF-8",
            "Access-Control-Allow-Origin": "*",
          },
        }
      );
      // send message
      this.name = "";
      this.message = "";
      this.email = null;
      this.verified = false;
      this.$refs.recaptcha.reset();
      this.contact = false;
    },
    logoClick() {
      window.location.href = "/";
    },
    termsClick() {
      window.location.href = "/terms";
    },
    privacyClick() {
      window.location.href = "/privacy";
    },
    onVerify: function() {
      this.verified = true;
    },
  },
};
</script>

<style>
.topbar {
  height: 100px;
  padding: 20px;
}
.logo {
  float: right;
  display: table;
  cursor: pointer;
  font-weight: 700;
}
.menu .logo {
  margin: 10px auto 30px;
  float: inherit;
  display: inline-flex;
}
.menu span {
  margin-top: 10px;
  color: #555;
}
.menu-list button {
  width: 100% !important;
  border-radius: 0px;
}
.menu-list button:hover {
  background: #ddd;
}
.menu-list button span {
  color: #555;
  font-size: 14pt;
}
.menu-list a {
  width: 100% !important;
  border-radius: 0px;
}
.menu-list a:hover {
  background: #ddd;
  font-weight: 400;
}
.menu-list a {
  color: #555;
  font-size: 12pt;
  display: block;
  margin: 0px;
  padding: 8px;
}
.logo img {
  width: 50px;
  border-radius: 25px;
  border: 2px solid;
  margin-right: 5px;
}
.logo span {
  font-size: 18pt;
  display: table-cell;
  vertical-align: middle;
}
.nav {
  display: inline-flex;
  margin-left: 80px;
  margin-top: 15px;
}
.v-navigation-drawer__content {
  padding: 10px;
}
.contact-content {
  text-align: center;
  height: calc(100% - 65px);
  background: #f1eded;
  border-radius: 15px;
  margin: 0px 15px;
  padding-left: 20px;
  padding-right: 20px;
}
.contact-content h1 {
  color: black;
  font-size: 30pt;
  padding-top: 70px;
  margin-bottom: 40px;
}
.v-input__slot {
  padding: 10px;
}
.v-messages__message {
  color: red !important;
}
.mobile-menu {
  display: none;
  position: relative;
  top: 10px;
}
.mobile-menu span i {
  font-size: 25pt !important;
}
.header-link {
  letter-spacing: 0.3px;
  font-size: 12pt;
  color: white !important;
  text-decoration: none;
  margin-top: 8px;
  margin-left: 20px;
  font-weight: 500;
}
.header-link:hover {
  font-weight: 700;
}
.header-link.contact {
  width: 70px;
}
.header-link.terms {
  width: 63px;
}
.header-link.privacy {
  width: 125px;
}
@media only screen and (max-width: 700px) {
  .nav {
    margin-left: 40px;
  }
}
@media only screen and (max-width: 600px) {
  .nav {
    display: none;
  }
  .mobile-menu {
    display: inline-flex;
    float: left;
  }
}
@media only screen and (max-width: 500px) {
  .topbar {
    text-align: center;
  }
  .logo {
    display: inline-table;
    float: inherit;
  }
}
@media only screen and (min-width: 1800px) {
  .topbar {
    height: 135px;
    padding: 40px;
  }
  .logo img {
    width: 75px;
    margin-right: 15px;
    border: 3px solid;
    border-radius: 40px;
  }
  .logo span {
    font-size: 27pt;
  }
  .contact-arrow {
    font-size: 100px !important;
  }
}
</style>
