import Vue from 'vue'
import VueRouter from 'vue-router'
import App from './App.vue'
import Home from './pages/home.vue'
import Terms from './pages/terms.vue'
import MobileTerms from './pages/mobile/terms.vue'
import Privacy from './pages/privacy.vue'
import MobilePrivacy from './pages/mobile/privacy.vue'
import ArticleView from './pages/toplev/index.vue'
import vuetify from './plugins/vuetify';
import './registerServiceWorker'
import firebase from 'firebase'

const routes = [
  { path: '/', component: Home },
  { path: '/terms', component: Terms },
  { path: '/privacy', component: Privacy },

  { path: '/mobile-terms', component: MobileTerms },
  { path: '/privacy-mobile', component: MobilePrivacy },

  { path: '/toplev/:id', component: ArticleView },
]

const router = new VueRouter({
  mode: 'history',
  routes
})

Vue.use(VueRouter)

Vue.config.productionTip = false

const firebaseConfig = {
  apiKey: "AIzaSyAkElvCcdbH0njr6fIlxQmCvMr-PIKL5MQ",
  authDomain: "fitshare-6eb2a.firebaseapp.com",
  databaseURL: "https://fitshare-6eb2a.firebaseio.com",
  projectId: "fitshare-6eb2a",
  storageBucket: "fitshare-6eb2a.appspot.com",
  messagingSenderId: "772758309931",
  appId: "1:772758309931:web:719bdb3c43eff71f1cae6e",
  measurementId: "G-X67BJWXB8Y"
};

firebase.initializeApp(firebaseConfig);
// firebase.analytics();

new Vue({
  vuetify,
  router,
  render: h => h(App)
}).$mount('#app')
