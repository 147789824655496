<template>
  <div class="main">
    <Topbar />
    <v-container>
      <v-row class="w-full mx-0">
        <v-col md="6" lg="6" sm="12" cols="12">
          <div class="site-info-text">
            <h1>Share your Journey</h1>
            <a class="donwload-appstore" href="https://apps.apple.com/us/app/fitshare-find-new-workouts/id1515613566" target="blank">
              <img src="/img/appstore.svg" />
            </a>
          </div>
        </v-col>
        <v-col md="6" lg="6" sm="12" cols="12" class="phone-layout">
          <div class="iphone">
            <video ref="videoRef" class="fitness-video" width="343px" height="647px" autoplay loop muted poster="/img/videoFirstScreen.png" style="object-fit: fill;">
              <source src="https://drive.google.com/uc?export=download&id=1LEHfZsXLBkJw-0AflOtKdF0x5tpIecbD" type="video/mp4" />
            </video>
            <div class="phone-img"></div>
          </div>
        </v-col>
      </v-row>
    </v-container>
  </div>
</template>
<script>
import Topbar from "@/components/topbar";
export default {
  components: {
    Topbar,
  },
  mounted() {
    this.$refs.videoRef.play();
  },
  methods: {
  },
  created() {
    document.body.style.overflow = "hidden";
  },
};
</script>
<style>
.site-info-text {
  margin-top: 100px;
  text-align: center;
}
.site-info-text h1 {
  font-size: 45pt;
  font-weight: 600;
  margin-top: 30px;
  margin-bottom: 150px;
}
.site-info-text h3 {
  font-size: 21pt;
  margin-top: 30px;
  margin-bottom: 70px;
  font-weight: 600;
}
.donwload-appstore img {
  width: 200px;
  border: 3px solid white;
  border-radius: 10px;
}
.donwload-appstore:hover img {
  opacity: 0.85;
}
.v-btn:before {
  opacity: 0 !important;
}
.phone-layout {
  padding-bottom: 0px !important;
  position: relative;
}
.iphone {
  text-align: center;
  position: absolute;
  left: 50%;
  transform: translate(-50%);
  bottom: 0px;
}
.iphone .phone-img {
  width: 363px;
  margin: 0 auto;
  height: 635px;
  background: url(/img/iphone.png);
  background-size: contain;
  position: relative;
  background-repeat: no-repeat;
  z-index: 0;
}
.fitness-video {
  top: 16px;
  left: 50%;
  transform: translate(-50%);
  width: 320px;
  position: absolute;
  height: 619px;
  border-radius: 15px 15px 0px 0px;
}

@media only screen and (max-width: 960px) {
  .site-info-text {
    margin-top: 40px;
  }
}
@media only screen and (max-width: 700px) {
  .site-info-text h1 {
    font-size: 35pt;
    margin-bottom: 50px;
  }
  .container {
    margin: 0px;
    padding: 0px !important;
    margin-left: 0px !important;
    margin-right: 0px !important;
  }
  .site-info-text {
    margin-top: 50px !important;
  }
}
@media only screen and (max-width: 500px) {
  .site-info-text {
    margin-top: 40px !important;
  }
  .site-info-text h1 {
    font-size: 27pt;
    margin-bottom: 35px;
  }
  .site-info-text h3 {
    font-size: 15pt;
  }
}
@media only screen and (min-width: 1800px) {
  .site-info-text {
    margin-top: -30px;
  }
}
@media only screen and (max-height: 900px) {
  .site-info-text {
    margin-top: 100px;
  }
}
@media only screen and (min-height: 900px) {
  .site-info-text {
    margin-top: -30px;
  }
}
@media only screen and (min-width: 1800px) {
  .site-info-text h1 {
    font-size: 53pt;
    margin-top: 75px;
    margin-bottom: 110px;
  }
  .site-info-text h3 {
    font-size: 27pt;
    margin-top: 75px;
    margin-bottom: 110px;
  }
  .donwload-appstore img {
    width: 230px;
  }
  .iphone .phone-img {
    width: 450px;
    height: 760px;
  }
  .fitness-video {
    width: 410px;
    height: 763px;
    top: 22px;
  }
}
@media only screen and (min-width: 2200px) {
  .iphone .phone-img {
    width: 540px;
    height: 912px;
  }
  .fitness-video {
    width: 492px;
    height: 915px;
  }
  .site-info-text {
    margin-top: 0px;
  }
}
.theme--dark.v-application{
  background: white;
}
</style>
<style scoped>
@media only screen and (max-width: 960px) {
  .iphone {
    position: relative;
    margin-top: 60px;
  }
}
</style>