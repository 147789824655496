<template>
  <div class="root">
    <Topbar />
    <div class="container">
      <v-row>
        <div name="termly-embed" data-id="8f162343-5d05-45ce-ad8c-ec7a4af620b3" data-type="iframe" style="width:100%"></div>
        <script type="application/javascript">
          (function(d, s, id) {
            var js,
              tjs = d.getElementsByTagName(s)[0];
            if (d.getElementById(id)) return;
            js = d.createElement(s);
            js.id = id;
            js.src = "https://app.termly.io/embed-policy.min.js";
            tjs.parentNode.insertBefore(js, tjs);
          })(document, "script", "termly-jssdk");
        </script>
      </v-row>
    </div>
  </div>
</template>

<script>
import Topbar from "@/components/topbar";
export default {
  components: { Topbar },
  methods: {
    
  },
  created() {
    var desiredSource = 'https://app.termly.io/embed-policy.min.js';
    var scripts       = document.getElementsByTagName('script');
    var alreadyLoaded = false;

    if(scripts.length){
        for(var scriptIndex in scripts) {
            if(!alreadyLoaded && desiredSource === scripts[scriptIndex].src) {
                alreadyLoaded = true;
            }
        }
    }
    if(alreadyLoaded){
      window.location.reload();
    }
    document.body.style.overflow = "auto";
  },
};
</script>

<style scoped>
.root {
  overflow: auto !important;
  background: white;
}
.topbar {
  background: rgb(99, 13, 13);
}
</style>
