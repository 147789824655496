import { render, staticRenderFns } from "./privacy.vue?vue&type=template&id=e536094a&scoped=true&"
import script from "./privacy.vue?vue&type=script&lang=js&"
export * from "./privacy.vue?vue&type=script&lang=js&"
import style0 from "./privacy.vue?vue&type=style&index=0&id=e536094a&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "e536094a",
  null
  
)

export default component.exports

/* vuetify-loader */
import installComponents from "!../../node_modules/vuetify-loader/lib/runtime/installComponents.js"
import { VRow } from 'vuetify/lib/components/VGrid';
installComponents(component, {VRow})
