<template>
  <div class="main">
    <Topbar />
    <v-container>
      <v-row class="w-full mx-0">
        <v-col cols="12" class="phone-layout">
          <div class="iphone">
            <video webkit-playsinline="true" playsinline="true" ref="postVideoRef" :src="this.post ? this.post.video : ''" class="fitness-video" width="343px" height="647px" autoplay :muted="muted" loop :poster="this.post ? this.post.thumbnail : ''" style="object-fit: fill;">
            </video>
            <div class="phone-img">
              <div class="article-info">
                <img v-if="avatar" :src="avatar" class="avatar" />
                <v-avatar v-else color="#820606" class="avatar" size="44">
                  <span class="white--text headline">{{ this.post ? this.post.owner.firstName.substr(0, 1) + this.post.owner.lastName.substr(0, 1) : '' }}</span>
                </v-avatar>
                <br />
                <v-icon size="30" class="icon">mdi-heart</v-icon><br />
                <p>{{ likeCount }}</p>
                <img src="/img/icons/comment.svg" alt="comment" class="icon" /><br />
                <p>{{ commentCount }}</p>
                <v-icon size="30" class="icon">mdi-share</v-icon><br />
                <p>{{ shareCount }}</p>
              </div>
              <div class="article-bottom-info">
                <div class="muscle-list">
                  <v-avatar v-for="(muscle, index) in muscles" :key="index" size="37">
                    <img :src="'/img/muscles/' + muscle" alt="avatar" class="avatar" />
                  </v-avatar>
                </div>
                <span class="owner-name">
                  {{ this.post ? '@' + this.post.owner.username : '' }}
                </span>
                <div class="article-title">
                  {{ this.post && this.post.workout ? this.post.workout.name : '' }} - {{ this.post ? this.post.message : '' }}
                </div>
                <div v-if="post && post.song" class="song-title">
                  <v-icon>mdi-playlist-music</v-icon>
                  {{ post.song.songTitle }}
                </div>
              </div>
              <v-btn large icon class="btn-volume" @click="volumeClick"><v-icon>{{ muted ? 'mdi-volume-off' : 'mdi-volume-high' }}</v-icon></v-btn>
            </div>
          </div>
        </v-col>
      </v-row>
    </v-container>
  </div>
</template>

<script>
import Topbar from "@/components/topbar";
import firebase from "firebase";
export default {
  components: {
    Topbar,
  },
  data() {
    return {
      postId: null,
      post: null,
      avatar: null,
      gender : null,
      muscles : [],
      likeCount: 0,
      shareCount: 0,
      commentCount: 0,
      muted: true
    };
  },
  created() {
    document.body.style.overflow = "hidden";
    this.postId = this.$route.params.id;
    let firestoreDB = firebase.firestore();
    let storage = firebase.storage();
    let realtimeDB = firebase.database();
    firestoreDB
      .collection("Posts")
      .doc(this.postId)
      .get()
      .then((doc) => {
        if (doc.exists) {
          this.post = doc.data();
          this.gender = this.post.owner.gender.toLowerCase()
          if (this.post.workout){
            this.post.workout.muscles.map(item =>{
              this.muscles.push(item.toLowerCase() + '_' + this.gender + '.png')
            })
          }
          storage
            .ref(this.post.owner.thumbnail)
            .getDownloadURL().then(url => {
              this.avatar = url;
            })
            .catch((error) => {
              console.log("Error getting avatar:", error);
            });
        } else {
          console.log("No such document!");
        }
      })
      .catch((error) => {
        console.log("Error getting document:", error);
      });
      realtimeDB.ref('Comments/' + this.postId).once('value').then(comments =>{
        this.commentCount = comments.numChildren();
      })
      realtimeDB.ref('Shares/' + this.postId).once('value').then(shares =>{
        this.shareCount = shares.numChildren();
      })
      realtimeDB.ref('Likes/' + this.postId).once('value').then(likes =>{
        this.likeCount = likes.numChildren();
      })
  },
  methods: {
    volumeClick(){
      this.muted = !this.muted
    }
  },
};
</script>

<style scoped>
.phone-layout {
  min-height: 640px;
}
.article-info {
  position: absolute;
  right: 30px;
  bottom: 10px;
}
.article-info .avatar {
  width: 46px;
  height: 46px;
  border-radius: 50%;
  border: 2px solid #820606;
  margin-bottom: 15px;
}
.article-info .icon {
  width: 26px;
  height: 26px;
  filter: drop-shadow(0px 3px 3px rgba(0, 0, 0, 0.4));
}
.article-info .v-icon.icon {
  margin-bottom: 3px;
}
.article-info p {
  filter: drop-shadow(0px 3px 3px rgba(0, 0, 0, 0.4));
  margin-bottom: 10px;
}

.article-bottom-info {
  position: absolute;
  bottom: 10px;
  left: 25px;
  text-align: left;
  width: 255px;
}
.article-bottom-info .muscle-list {
  display: flex;
  overflow: auto;
  -ms-overflow-style: none; /* IE and Edge */
  scrollbar-width: none; /* Firefox */
}
.article-bottom-info .muscle-list::-webkit-scrollbar {
  height: 0px;
}
.article-bottom-info .muscle-list .v-avatar {
  margin-right: 5px;
}
.article-bottom-info .owner-name {
  font-size: 15pt;
  font-weight: 700;
}
.article-bottom-info .article-title {
  font-size: 10pt;
}
.iphone video{
  background: black;
}
.btn-volume{
  position: absolute;
  right: 26px;
  top: 27px;
}
</style>
